import Dinero from 'dinero.js';
import { CustomRule } from 'vuelidate/lib/validators';

import { ACCOUNT_TYPES } from '@/constants';

export const greaterThanZeroValidator: CustomRule = (value) => Number(value) > 0;

export function formatCents(cents: number): string {
	return Dinero({
		amount: cents,
		currency: 'USD',
	})
		.toFormat();
}

export function getAaUrl(loanId: string): string {
	return `https://${ process.env.VUE_APP_RAILS_DOMAIN }/admin/loans/${ loanId }`;
}

export function capitalizeString(value: string): string {
	return value[0].toUpperCase() + value.slice(1, value.length);
}

export function getPrettyAccountType(type: string): string {
	const map = {
		[ACCOUNT_TYPES.INVESTOR]: 'Investor',
		[ACCOUNT_TYPES.INVESTOR_AUTO]: 'Auto-Investor',
		[ACCOUNT_TYPES.INVESTOR_IRA]: 'IRA',
		[ACCOUNT_TYPES.INVESTOR_YIELD]: 'Stairs',
	};

	return map[type];
}
