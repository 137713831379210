export const UNDEFINED_RAILS_DOMAIN_VALUE = 'undefined-rails-domain';
export const UNDEFINED_GQL_ENDPOINT_VALUE = 'undefined-gql-endpoint';
export const UNDEFINED_CONSUMER_TOKEN_VALUE = 'undefined-initial-token';
export const UNDEFINED_API_SECRET_VALUE = 'undefined-api-secret';

// TODO : Move to conduit?
export const US_STATES = {
	AL: 'Alabama',
	AK: 'Alaska',
	AS: 'American Samoa',
	AZ: 'Arizona',
	AR: 'Arkansas',
	CA: 'California',
	CO: 'Colorado',
	CT: 'Connecticut',
	DE: 'Delaware',
	DC: 'District Of Columbia',
	FM: 'Federated States Of Micronesia',
	FL: 'Florida',
	GA: 'Georgia',
	GU: 'Guam',
	HI: 'Hawaii',
	ID: 'Idaho',
	IL: 'Illinois',
	IN: 'Indiana',
	IA: 'Iowa',
	KS: 'Kansas',
	KY: 'Kentucky',
	LA: 'Louisiana',
	ME: 'Maine',
	MH: 'Marshall Islands',
	MD: 'Maryland',
	MA: 'Massachusetts',
	MI: 'Michigan',
	MN: 'Minnesota',
	MS: 'Mississippi',
	MO: 'Missouri',
	MT: 'Montana',
	NE: 'Nebraska',
	NV: 'Nevada',
	NH: 'New Hampshire',
	NJ: 'New Jersey',
	NM: 'New Mexico',
	NY: 'New York',
	NC: 'North Carolina',
	ND: 'North Dakota',
	MP: 'Northern Mariana Islands',
	OH: 'Ohio',
	OK: 'Oklahoma',
	OR: 'Oregon',
	PW: 'Palau',
	PA: 'Pennsylvania',
	PR: 'Puerto Rico',
	RI: 'Rhode Island',
	SC: 'South Carolina',
	SD: 'South Dakota',
	TN: 'Tennessee',
	TX: 'Texas',
	UT: 'Utah',
	VT: 'Vermont',
	VI: 'Virgin Islands',
	VA: 'Virginia',
	WA: 'Washington',
	WV: 'West Virginia',
	WI: 'Wisconsin',
	WY: 'Wyoming',
};

export const DEFAULT_PAGE_TITLE = 'Welcome to Ground Control!';
export const SUPER_ADMIN = 'SUPER_ADMIN';
export const UNDERWRITER = 'UNDERWRITER';
export const LOAN_MANAGER = 'LOAN_MANAGER';
export const PRESS_MANAGER = 'PRESS_MANAGER';
export const FRAUD_OFFICER = 'FRAUD_OFFICER';
export const DATA_ADMIN = 'DATA_ADMIN';
export const BDM = 'BDM';
export const PROCESSOR = 'PROCESSOR';
export const IRA_ADMIN = 'IRA_ADMIN';
export const LOAN_OFFICER = 'LOAN_OFFICER';
export const PROMOTIONAL_CREDIT_APPROVER = 'PROMOTIONAL_CREDIT_APPROVER';
export const FINANCE_OFFICER = 'FINANCE_OFFICER';
export const CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT';
export const MARKETING_MANAGER = 'MARKETING_MANAGER';

export const EMPLOYEE_ROLES = {
	DEFAULT_PAGE_TITLE,
	SUPER_ADMIN,
	UNDERWRITER,
	LOAN_MANAGER,
	PRESS_MANAGER,
	FRAUD_OFFICER,
	DATA_ADMIN,
	BDM,
	PROCESSOR,
	IRA_ADMIN,
	LOAN_OFFICER,
	PROMOTIONAL_CREDIT_APPROVER,
	FINANCE_OFFICER,
	CUSTOMER_SUPPORT,
	MARKETING_MANAGER,
};

export const INVESTOR_ACCOUNT_TYPE = 'INVESTOR';
export const INVESTOR_AUTO_ACCOUNT_TYPE = 'INVESTOR_AUTO';
export const INVESTOR_IRA_ACCOUNT_TYPE = 'INVESTOR_IRA';
export const INVESTOR_YIELD_TYPE = 'INVESTOR_YIELD';

export const ACCOUNT_TYPES = {
	INVESTOR: INVESTOR_ACCOUNT_TYPE,
	INVESTOR_AUTO: INVESTOR_AUTO_ACCOUNT_TYPE,
	INVESTOR_IRA: INVESTOR_IRA_ACCOUNT_TYPE,
	INVESTOR_YIELD: INVESTOR_YIELD_TYPE,
};
