import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client/core';
import VueApollo from 'vue-apollo';

import store from '@/store/store';

const httpLink = createHttpLink({
	uri: process.env.VUE_APP_GQL_ENDPOINT,
});

const authLink = new ApolloLink((operation, forward) => {
	const token = store.state.token || store.state.consumerToken || store.state.apiSecret;

	operation.setContext({
		headers: {
			authorization: (store.state.token || store.state.consumerToken) ? `Bearer ${ token }` : `Token token=${ token }`,
		},
	});

	// Call the next link in the middleware chain.
	return forward(operation);
});

// Cache implementation
const cache = new InMemoryCache({
	resultCaching: false,
});

// Create the apollo client
const apolloClient = new ApolloClient({
	link: authLink.concat(httpLink),
	cache,
	// Keeping this around for when I inevitably regret turning cache on - BW 1/21
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'no-cache',
		},
		query: {
			fetchPolicy: 'no-cache',
		},
		mutate: {
			fetchPolicy: 'no-cache',
		},
	},
});

const apolloProvider = new VueApollo({
	defaultClient: apolloClient,
});

export default apolloProvider;
