import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import store from '@/store/store';
import { StringMap } from '@/types/general.types';
import PendingTransfers from '@/views/administration/PendingTransfers.vue';
import Dashboard from '@/views/Dashboard.vue';
import ListProgressUpdates from '@/views/list-progress-updates/list-progress-updates.view.vue';
import Login from '@/views/login/login.view.vue';
import Private from '@/views/private/private.view.vue';
import ProgressUpdates from '@/views/progress-updates/progress-updates.view.vue';
import PublishProgressUpdate from '@/views/publish-progress-update/publish-progress-update.view.vue';
import PublishableLoans from '@/views/publishable-loans/publishable-loans.view.vue';
import Publisher from '@/views/publisher/publisher.view.vue';

Vue.use(VueRouter);

export const namedRoutes: StringMap<RouteConfig> = {
	Dashboard: {
		path: '/',
		name: 'Dashboard',
		component: Dashboard,
	},
	Login: {
		path: '/login',
		name: 'Login',
		component: Login,
	},
	Publisher: {
		path: '/publisher/:loanId',
		name: 'Publisher',
		component: Publisher,
	},
	PublishableLoans: {
		path: '/publishable-loans',
		name: 'PublishableLoans',
		component: PublishableLoans,
	},
	ProgressUpdates: {
		path: '/progress-updates',
		component: ProgressUpdates,
	},
	ListProgressUpdates: {
		path: '',
		name: 'ListProgressUpdates',
		component: ListProgressUpdates,
	},
	PublishProgressUpdate: {
		path: 'publish/:progressUpdateId',
		name: 'PublishProgressUpdate',
		component: PublishProgressUpdate,
	},
	PendingTransfers: {
		path: '/pending-transfers',
		name: 'PendingTransfers',
		component: PendingTransfers,
	},
};

export const privateRoute: RouteConfig = {
	path: '',
	component: Private,
	children: [
		namedRoutes.Dashboard,
		namedRoutes.Publisher,
		namedRoutes.PublishableLoans,
		namedRoutes.PendingTransfers,
		{
			...namedRoutes.ProgressUpdates,
			children: [
				namedRoutes.ListProgressUpdates,
				namedRoutes.PublishProgressUpdate,
			],
		},
	],
	beforeEnter: (to, from, next) => {
		const hasToken = !!store.state.token;

		if (!hasToken) {
			next(namedRoutes.Login);
		} else {
			next();
		}
	},
};

const routes: Array<RouteConfig> = [
	namedRoutes.Login,
	privateRoute,
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	const isGoingToLogin = to.name === namedRoutes.Login.name;
	const hasToken = !!store.state.token;

	if (isGoingToLogin && hasToken) {
		next(namedRoutes.Dashboard);
	} else if (!isGoingToLogin && !hasToken) {
		next(namedRoutes.Login);
	} else {
		next();
	}
});

export default router;
