import { MutationErrors } from '@/api/models/mutation-errors';

export class GfError implements Error {
	// Fields from Error interface
	name: string;
	message: string;
	stack?: string | undefined;
	cause?: unknown;

	// Fields from the API

	/** Error code describing the nature of the failure */
	code: string;

	/** Numeric status code which correlates to http status codes */
	status: string;

	/** Human- (but not necessarily user-) readable error details */
	detail: string;

	/** Rarely used further location information for the error */
	pointer: string;

	constructor(code: string, status: string, detail: string, pointer: string) {
		this.code = code;
		this.detail = detail;
		this.pointer = pointer;
		this.status = status;

		this.name = this.status;
		this.message = `${ code } (${ status }): ${ detail }`;
	}

	/** Takes MutationErrors and converts them into a GfErrors */
	static fromMutationErrors(mutationErrors: MutationErrors): GfError[] {
		return mutationErrors.errors.map(
			(e) => new GfError(e.code, e.status, e.detail, e.pointer),
		);
	}

	/** Takes MutationErrors and returns the first one converted into a GfError */
	static firstFromMutationErrors(mutationErrors: MutationErrors): GfError {
		return this.fromMutationErrors(mutationErrors)[0];
	}
}
