import {
	Loan,
	PublishableProgressUpdate,
	publishProgressUpdates,
} from '@gffinance/conduit';
import Vue from 'vue';
import { VueEditor } from 'vue2-editor';

import { namedRoutes } from '@/router/router';

export default Vue.extend({
	name: 'ListProgressUpdates',
	components: {
		VueEditor,
	},
	data() {
		return {
			customToolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }]],
			isPublishing: false,
			selectedLoans: [] as Loan[],
			updatedHtml: '',
		};
	},
	computed: {
		showUpdate(): boolean {
			return !this.showLoading && this.progressUpdate !== null;
		},
		showNoResults(): boolean {
			return !this.showLoading && this.progressUpdate === null;
		},
		showLoading(): boolean {
			return this.$store.state.isLoadingUpdates;
		},
		progressUpdate(): PublishableProgressUpdate | null {
			return this.$store.getters.progressUpdates.reduce((found: PublishableProgressUpdate | null, update: PublishableProgressUpdate) => {
				return (update.id === this.$route.params.progressUpdateId) ? update : found;
			}, null);
		},
		canPublish(): boolean {
			return (this.progressUpdate !== null && !this.isPublishing && this.selectedLoans.length > 0);
		},
	},
	watch: {
		'progressUpdate.html': function(value) {
			this.updatedHtml = value;
		},
	},
	mounted() {
		if (this.progressUpdate) {
			this.updatedHtml = this.progressUpdate.html;
		}
	},
	methods: {
		publish() {
			if (this.progressUpdate !== null) {
				this.isPublishing = false;

				publishProgressUpdates({
					apollo: this.$apollo.getClient(),
					input: {
						progressUpdateId: this.progressUpdate.id,
						investorUpdates: this.selectedLoans.map(loan => {
							return {
								// TS is unhappy here because when used in a request loan id can be null, but here it will always be a string as the source is a response. Hence the as.
								loanId: loan.id as string,
								html: this.updatedHtml,
							};
						}),
					},
				})
					.then(() => {
						this.$buefy.toast.open({
							message: 'Progress update published',
							type: 'is-success',
						});
						this.$router.push({ name: namedRoutes.ListProgressUpdates.name });
					})
					.catch((e) => {
						console.error(e);
						this.$buefy.toast.open({
							message: e.message,
							type: 'is-danger',
						});
					})
					.finally(() => {
						this.isPublishing = false;
					});
			} else {
				console.error('Something impossible has happened');
			}
		},
	},
});
