import { ProgressUpdateStates } from '@gffinance/conduit';
import { GetterTree } from 'vuex';

import { EMPLOYEE_ROLES } from '@/constants';
import { RootState } from '@/types/general.types';

const getters: GetterTree<RootState, RootState> = {
	progressUpdates: state => state.unfilteredProgressUpdates.filter(p => p.state === ProgressUpdateStates.unpublished),
	isSuperAdmin(state) {
		return state.employee === null ? false : state.employee.employeeRoles.some(employeeRole => employeeRole.name === EMPLOYEE_ROLES.SUPER_ADMIN);
	},
	isPressManager(state) {
		return state.employee === null ? false : state.employee.employeeRoles.some(employeeRole => employeeRole.name === EMPLOYEE_ROLES.PRESS_MANAGER);
	},
	isMarketingManager(state) {
		return state.employee === null ? false : state.employee.employeeRoles.some(employeeRole => employeeRole.name === EMPLOYEE_ROLES.MARKETING_MANAGER);
	},
	isFinanceOfficer(state) {
		return state.employee === null ? false : state.employee.employeeRoles.some(employeeRole => employeeRole.name === EMPLOYEE_ROLES.FINANCE_OFFICER);
	},
	isLoanOfficer(state) {
		return state.employee === null ? false : state.employee.employeeRoles.some(employeeRole => employeeRole.name === EMPLOYEE_ROLES.LOAN_OFFICER);
	},
	isPromotionalCreditApprover(state) {
		return state.employee === null ? false : state.employee.employeeRoles.some(employeeRole => employeeRole.name === EMPLOYEE_ROLES.PROMOTIONAL_CREDIT_APPROVER);
	},
	canDoPublisher(state, getters) {
		return getters.isSuperAdmin || getters.isLoanOfficer || getters.isFinanceOfficer || getters.isPromotionalCreditApprover;
	},
};

export default getters;
