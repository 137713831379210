import {
	fetchPublishableLendingLoans,
	PublishableLendingLoan,
} from '@gffinance/conduit';
import Fuse from 'fuse.js';
import Vue from 'vue';

import { namedRoutes } from '@/router/router';
import { formatCents } from '@/utils';

export default Vue.extend({
	name: 'PublishableLoan',
	data() {
		return {
			searchText: '',
			isLoading: true,
			unfilteredLendingLoans: [] as PublishableLendingLoan[],
			perPage: 12,
			currentPage: 1,
			filterOptions: {
				includeScore: true,
				includeMatches: true,
				distance: 100,
				threshold: 0.1,
				ignoreLocation: true,
				keys: [
					'id',
					'name',
				],
			},
		};
	},
	computed: {
		isPaginated(): boolean {
			return this.unfilteredLendingLoans.length > this.perPage;
		},
		isSearching(): boolean {
			return this.searchText.length > 1;
		},
		filteredLendingLoans(): PublishableLendingLoan[] {
			const searchResults = new Fuse(this.unfilteredLendingLoans, this.filterOptions);
			const searchedLendingLoans = this.isSearching ? searchResults.search(this.searchText) : [];

			return searchedLendingLoans.map(searchedLendingLoan => searchedLendingLoan.item);
		},
		lendingLoans(): PublishableLendingLoan[] {
			return this.isSearching ? this.filteredLendingLoans : this.unfilteredLendingLoans;
		},
	},
	mounted() {
		this.loadLoans();
	},
	methods: {
		formatCents,
		getPublishTo(loanId: number) {
			return {
				name: namedRoutes.Publisher.name,
				params: {
					loanId,
				},
			};
		},
		loadLoans(): void {
			this.isLoading = true;

			fetchPublishableLendingLoans(this.$apollo.getClient())
				.then((lendingLoans) => {
					this.unfilteredLendingLoans = lendingLoans;
					this.isLoading = false;
				})
				.catch((e) => {
					console.error(e);
					this.$buefy.toast.open({
						message: e.message,
						type: 'is-danger',
					});
				});
		},
	},
});
