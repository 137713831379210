import { gql } from '@apollo/client/core';

export const SEC_BATCHES_QUERY = gql`
	query SecBatches {
		secBatches {
			id
      displayName
		}
	}
`;
