import Vue from 'vue';

export default Vue.extend({
	name: 'ProgressUpdates',
	mounted() {
		// Dispatching here to cover the detail view when it is navigated to directly
		// This dispatch is duplicated in the list view intentionally.
		this.$store.dispatch('loadProgressUpdates', this.$apollo.getClient())
			.catch((e) => {
				console.error(e);
				this.$buefy.toast.open({
					message: e.message,
					type: 'is-danger',
				});
			});
	},
});
