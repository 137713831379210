import { library } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEnvelope, faLock, faCalendar, faAngleLeft, faAngleRight, faExclamationCircle, faPercent, faPercentage, faMinus, faPlus, faArrowUp, faArrowLeft, faArrowRight, faArrowDown, faCaretUp, faCaretDown, faSignOutAlt, faBroadcastTower, faFileExport, faTachometerAlt, faHammer, faCheckCircle, faClock, faSearch, faMapMarkedAlt, faBullhorn, faTrashAlt, faPencilAlt, faUniversity } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { buefyConfig, orugaConfig, plugin } from '@gffinance/groundfloor-ui';
import Oruga from '@oruga-ui/oruga';
import Buefy from 'buefy';
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import VueCurrencyInput from 'vue-currency-input';
import VueMeta from 'vue-meta';

import App from './App.vue';
import router from './router/router';
import store from './store/store';

import './assets/global-styles/global-bundle.scss';
import apolloProvider from '@/apollo-client';

library.add(faEye, faEnvelope, faLock, faCalendar, faAngleLeft, faAngleRight, faExclamationCircle, faPercent, faPercentage, faMinus, faPlus, faArrowUp, faArrowRight, faArrowLeft, faArrowDown, faCaretUp, faCaretDown, faSignOutAlt, faBroadcastTower, faFileExport, faTachometerAlt, faHammer, faCheckCircle, faClock, faSearch, faMapMarkedAlt, faCaretDown, faCaretUp, faBullhorn, faTrashAlt, faPencilAlt, faUniversity);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.use(Buefy, buefyConfig);
Vue.use(Oruga, {
	iconComponent: 'font-awesome-icon',
	iconPack: 'fas',
	...orugaConfig,
	checkbox: {
		rootClass: 'checkbox',
	},
});
Vue.use(plugin);
Vue.use(VueApollo);
Vue.use(VueMeta);
Vue.use(VueCurrencyInput, {
	globalOptions: {
		currency: 'USD',
		locale: 'en-US',
		distractionFree: false,
		valueAsInteger: true,
		allowNegative: false,
		valueRange: { min: 0 },
	},
});

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	apolloProvider,
	render: h => h(App),
}).$mount('#app');
