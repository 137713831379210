
import Fuse from 'fuse.js';
import { defineComponent } from 'vue';

import { callGfMutation } from '@/api/gf-mutation';
import {
	PENDING_FUNDS_TRANSFERS_QUERY,
	APPROVE_FUNDS_TRANSFERS_QUERY,
	APPROVE_FUNDS_TRANSFERS_MUTATION_NAME,
	REJECT_FUNDS_TRANSFERS_QUERY,
	REJECT_FUNDS_TRANSFERS_MUTATION_NAME,
} from '@/api/queries/funds-transfers';
import { capitalizeString, formatCents, getPrettyAccountType } from '@/utils';

export default defineComponent({
	name: 'PendingTransfers',
	components: {},
	data() {
		return {
			loading: true,
			searchText: '',
			unfilteredTransfers: [],
			selectedTransfers: [],
			filterOptions: {
				includeScore: true,
				includeMatches: true,
				distance: 100,
				threshold: 0.1,
				ignoreLocation: true,
				keys: [
					'id',
					'accountType',
					'userName',
				],
			},
		};
	},
	computed: {
		searching(): boolean {
			return this.searchText.length > 0;
		},
		filteredTransfers(): object[] {
			const searchResults = new Fuse(this.unfilteredTransfers, this.filterOptions);
			const searchedTransfers = this.searching ? searchResults.search(this.searchText) : [];

			return searchedTransfers.map(searchedTransfer => searchedTransfer.item);
		},
		fundsTransfers(): object[] {
			return this.searching ? this.filteredTransfers : this.unfilteredTransfers;
		},
	},
	mounted() {
		this.fetchPendingTransfers();
	},
	methods: {
		formatCents,
		capitalizeString,
		getPrettyAccountType,
		callGfMutation,
		// A little silly, but I want a handy way to determine if the "direct object"
		// of a sentence should be "transfer" singular or "transfers" plural.
		deriveSentenceDirectObject(ids) {
			return ids.length > 1 ? 'transfers' : 'transfer';
		},
		confirmApproval(transferId = null) {
			const transferIds = transferId ? [transferId] : this.selectedTransfers;

			this.$buefy.dialog.confirm({
				title: 'Heads Up!',
				message: `You are about to approve ${ transferIds.length } funds ${ this.deriveSentenceDirectObject(transferIds) }. Would you like to proceed?`,
				cancelText: 'No',
				confirmText: 'Yes',
				type: 'is-primary',
				hasIcon: false,
				onConfirm: () => this.submitApproval(transferIds),
			});
		},
		confirmRejection(transferId = null) {
			const transferIds = transferId ? [transferId] : this.selectedTransfers;

			this.$buefy.dialog.confirm({
				title: 'Heads Up!',
				message: `You are about to reject ${ transferIds.length } funds ${ this.deriveSentenceDirectObject(transferIds) }. Would you like to proceed?`,
				cancelText: 'No',
				confirmText: 'Yes',
				type: 'is-danger',
				hasIcon: false,
				onConfirm: () => this.submitRejection(transferIds),
			});
		},
		async submitApproval(transferIds) {
			this.loading = true;
			this.selectedTransfers = [];

			this.callGfMutation(
				this.$apollo,
				APPROVE_FUNDS_TRANSFERS_QUERY,
				APPROVE_FUNDS_TRANSFERS_MUTATION_NAME,
				{ fundsTransferIds: transferIds },
			)
				.then(() => {
					this.$buefy.toast.open({
						message: `Funds ${ this.deriveSentenceDirectObject(transferIds) } successfully approved!`,
						type: 'is-success',
					});

					this.fetchPendingTransfers();
				})
				.catch((error) => {
					console.error(error);

					this.loading = false;

					this.$buefy.toast.open({
						message: error.message,
						type: 'is-danger',
					});
				});
		},
		async submitRejection(transferIds) {
			this.loading = true;
			this.selectedTransfers = [];

			this.callGfMutation(
				this.$apollo,
				REJECT_FUNDS_TRANSFERS_QUERY,
				REJECT_FUNDS_TRANSFERS_MUTATION_NAME,
				{ fundsTransferIds: transferIds },
			)
				.then(() => {
					this.$buefy.toast.open({
						message: `Funds ${ this.deriveSentenceDirectObject(transferIds) } successfully rejected!`,
						type: 'is-success',
					});

					this.fetchPendingTransfers();
				})
				.catch((error) => {
					console.error(error);

					this.loading = false;

					this.$buefy.toast.open({
						message: error.message,
						type: 'is-danger',
					});
				});
		},
		async fetchPendingTransfers() {
			this.loading = true;

			try {
				const { data } = await this.$apollo.query({
					query: PENDING_FUNDS_TRANSFERS_QUERY,
					variables: {},
				});

				this.unfilteredTransfers = data.fundsTransfers.map((transfer) => {
					return {
						id: Number(transfer.id),
						amountCents: transfer.amountCents,
						direction: transfer.direction,
						accountType: transfer.account.type,
						userName: transfer.user.name,
					};
				});

				this.loading = false;
			} catch (error) {
				console.error(error);

				this.$buefy.toast.open({
					message: error.message,
					type: 'is-danger',
				});
			}
		},
	},
});
