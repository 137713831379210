import { ApolloClient } from '@apollo/client/core';
import {
	fetchPublishableProgressUpdates,
	getConsumerToken,
	getEmployeeTokenByAuthCredentials,
	fetchEmployeeByClient,
} from '@gffinance/conduit';
import { getEmployeeJwtCookie } from '@gffinance/groundfloor-ui';
import { ActionTree } from 'vuex';

import { LoginArguments, RootState } from '@/types/general.types';

let loadProgressUpdatesPromise: Promise<void> | null = null;

const actions: ActionTree<RootState, RootState> = {
	init({ state, commit, dispatch }, apollo: ApolloClient<unknown>): Promise<void> {
		if (state.initPromise) {
			return state.initPromise;
		} else {
			const initPromise = new Promise<void>((resolve, reject) => {
				const token = getEmployeeJwtCookie();

				if (token) {
					commit('setToken', token);

					dispatch('loadUserInformation', apollo)
						.then(() => {
							resolve();
						})
						.catch(reject);
				} else {
					resolve();
				}
			});

			commit('setInitPromise', initPromise);

			return initPromise;
		}
	},
	loadUserInformation({ dispatch }, apollo: ApolloClient<unknown>): Promise<void> {
		return new Promise((resolve, reject) => {
			Promise.all([
				dispatch('loadProfile', apollo),
			])
				.then(() => {
					resolve();
				})
				.catch(reject);
		});
	},
	loadProfile({ commit }, apollo: ApolloClient<unknown>): Promise<void> {
		return new Promise((resolve, reject) => {
			fetchEmployeeByClient(apollo)
				.then((employee) => {
					commit('setEmployee', employee);
					resolve();
				})
				.catch(reject);
		});
	},
	login({ commit, dispatch }, { apollo, credentials }: LoginArguments): Promise<void> {
		return new Promise((resolve, reject) => {
			getConsumerToken(apollo)
				.then((consumerToken) => {
					commit('setConsumerToken', consumerToken);
					getEmployeeTokenByAuthCredentials(apollo, credentials)
						.then((token) => {
							commit('setToken', token);

							dispatch('loadUserInformation', apollo)
								.then(() => {
									resolve();
								})
								.catch(reject);
						})
						.catch(reject);
				})
				.catch(reject);
		});
	},
	logout({ commit }): void {
		commit('setToken', null);
		commit('setEmployee', null);
		commit('setCurrentBalanceInCents', 0);
	},
	loadProgressUpdates({ commit }, apollo: ApolloClient<unknown>): Promise<void> {
		if (!loadProgressUpdatesPromise) {
			loadProgressUpdatesPromise = new Promise((resolve, reject) => {
				commit('setIsLoadingUpdates', true);

				fetchPublishableProgressUpdates(apollo)
					.then((progressUpdates) => {
						commit('setUnfilteredProgressUpdates', progressUpdates);
						resolve();
					})
					.catch(reject)
					.finally(() => {
						commit('setIsLoadingUpdates', false);
						loadProgressUpdatesPromise = null;
					});
			});
		}

		return loadProgressUpdatesPromise;
	},
};

export default actions;
