import { DocumentNode } from '@apollo/client';
import { DollarApollo } from 'vue-apollo/types/vue-apollo';

import { MutationErrors } from './models/mutation-errors';

import { GfError } from '@/api/models/gf-error';

export type Apollo = DollarApollo<object>;

/** Calls the provided mutation and returns a promise that resolves with the returned data.
 * @param apollo The apollo thingamajig
 * @param mutation The graphql mutation to execute
 * @param operationName The name of the mutation whose response data is represented by `TOutput`.  For example, `"issueUserJwt"`
 * @param variables The input variables for the mutation if necessary
 * */
export async function callGfMutation<TInput, TOutput extends MutationErrors>(
	apollo: Apollo,
	mutation: DocumentNode,
	mutationName: string,
	variables: TInput | undefined = undefined,
): Promise<TOutput> {
	return apollo
		.mutate<TOutput, TInput>({
			mutation,
			variables: { input: variables },
			errorPolicy: 'all',
		})
		.then((res) => {
			const { data: rawData, errors: gqlErrors } = res;

			if (gqlErrors) {
				console.error('GraphQL has complained about an invalid request:');
				console.error(gqlErrors);
				throw new Error('An unexpected error has occurred.  Please try again.');
			}

			// GraphQL places response data under keys named after the mutation being called.
			// In order to not make consumers of this output deal with that, we can extract it here.
			const data = rawData[mutationName] as TOutput;

			if (data.errors?.length) {
				console.error(data.errors);
				// Technically we can have multiple errors, but in reality there's usually just one
				throw GfError.firstFromMutationErrors(data);
			}

			return data;
		});
}

/** Handy dandy function that generates an even handier and dandier function that
 * makes calling a gql mutation with strong typing a breeze.
 * @param mutation The graphql mutation to execute
 * @param operationName The name of the mutation whose response data is represented by `TOutput`.  For example, `"issueUserJwt"`
 */
export function buildMutationWrapper<TInput, TOutput extends MutationErrors>(
	mutation: DocumentNode,
	mutationName: string,
) {
	return function(apollo: Apollo, params: TInput): Promise<TOutput> {
		return callGfMutation<TInput, TOutput>(
			apollo,
			mutation,
			mutationName,
			params,
		);
	};
}
