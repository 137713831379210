import { getEmployeeJwtCookie } from '@gffinance/groundfloor-ui';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

import {
	UNDEFINED_API_SECRET_VALUE,
	UNDEFINED_GQL_ENDPOINT_VALUE,
} from '@/constants';
import {
	RootState,
} from '@/types/general.types';

Vue.use(Vuex);

export const storeOptions: StoreOptions<RootState> = {
	state: {
		gqlEndpoint: process.env.VUE_APP_GQL_ENDPOINT || UNDEFINED_GQL_ENDPOINT_VALUE,
		consumerToken: null,
		apiSecret: process.env.VUE_APP_API_SECRET || UNDEFINED_API_SECRET_VALUE,
		initPromise: null,
		token: getEmployeeJwtCookie() || null,
		employee: null,
		currencyCode: 'USD',
		unfilteredProgressUpdates: [],
		isLoadingUpdates: false,
	},
	mutations,
	actions,
	getters,
};

export default new Vuex.Store(storeOptions);
