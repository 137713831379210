import Vue from 'vue';

import { DEFAULT_PAGE_TITLE } from '@/constants';

export default Vue.extend({
	name: 'App',
	mounted() {
		this.$store.dispatch('init', this.$apollo.getClient())
			.catch((e) => {
				console.error('Error loading profile!', e);
				this.$buefy.toast.open({
					message: e.message,
					type: 'is-danger',
				});
			});
	},
	metaInfo: {
		title: DEFAULT_PAGE_TITLE,
	},
});
