import {
	fetchLendingLoan,
	getLendingDataConversionInputFromLendingLoan,
	LendingLoan,
	GfImage,
} from '@gffinance/conduit';
import { useVuelidate } from '@vuelidate/core';
import {
	required,
	minValue,
	maxValue,
	numeric,
	integer,
	decimal,
	minLength,
	maxLength,
	alpha,
} from '@vuelidate/validators';
import { DateTime } from 'luxon';
import { defineComponent, reactive } from 'vue';

import { callGfMutation } from '@/api/gf-mutation';
import {
	CREATE_INVESTMENT_OPPORTUNITY_FROM_LENDING_DATA_QUERY,
} from '@/api/queries/publisher';
import { SEC_BATCHES_QUERY } from '@/api/queries/sec-batches';
import { US_STATES } from '@/constants';
import { getAaUrl } from '@/utils';

enum VALID_LETTER_GRADES {
	A = 'A',
	B = 'B',
	C = 'C',
	D = 'D',
	E = 'E',
	F = 'F',
	G = 'G'
}

enum LOAN_PURPOSES {
	ACQUISITION_CONSTRUCTION = 'Acquisition & New Construction',
	CONSTRUCTION = 'New Construction',
	PURCHASE = 'Purchase',
	PURCHASE_RENO = 'Purchase & Renovation',
	REFI_CASH = 'Refinance - Cash Out',
	REFI_RENO = 'Refinance - Rehab'
}

const LABELED_LOAN_PURPOSES: Map<LOAN_PURPOSES, string> = new Map([
	[LOAN_PURPOSES.ACQUISITION_CONSTRUCTION, 'Acquisition & New Construction'],
	[LOAN_PURPOSES.CONSTRUCTION, 'New Construction'],
	[LOAN_PURPOSES.PURCHASE, 'Purchase'],
	[LOAN_PURPOSES.PURCHASE_RENO, 'Purchase & Renovation'],
	[LOAN_PURPOSES.REFI_CASH, 'Refinance - Cash Out'],
	[LOAN_PURPOSES.REFI_RENO, 'Refinance - Rehab'],
]);

enum PROPERTY_TYPES {
	SINGLE_FAMILY = 'Single-family Home',
	TOWNHOME = 'Townhome',
	CONDO = 'Condo',
	LAND = 'Land',
	MULTI_FAMILY_TWO = 'Multi-family Home (2 units)',
	MULTI_FAMILY_TWO_TO_FOUR = 'Multi-family (2-4 units)',
	MULTI_FAMILY_THREE = 'Multi-family Home (3 units)',
	MULTI_FAMILY_FOUR = 'Multi-family Home (4 units)',
	MULTI_FAMILY_FIVE = 'Multi-family Home (5+ units)'
}

const LABELED_PROPERTY_TYPES: Map<PROPERTY_TYPES, string> = new Map([
	[PROPERTY_TYPES.SINGLE_FAMILY, 'Single-family Home'],
	[PROPERTY_TYPES.CONDO, 'Condo'],
	[PROPERTY_TYPES.LAND, 'Land'],
	[PROPERTY_TYPES.MULTI_FAMILY_TWO, 'Multi-family Home (2 units)'],
	[PROPERTY_TYPES.MULTI_FAMILY_TWO_TO_FOUR, 'Multi-family (2-4 units)'],
	[PROPERTY_TYPES.MULTI_FAMILY_THREE, 'Multi-family Home (3 units)'],
	[PROPERTY_TYPES.MULTI_FAMILY_FOUR, 'Multi-family Home (4 units)'],
	[PROPERTY_TYPES.MULTI_FAMILY_FIVE, 'Multi-family Home (5+ units)'],
]);

enum PAYMENT_TYPES {
	MONTHLY = 'pay_monthly',
	DEFERRED = 'deferred_till_due'
}

const LABELED_PAYMENT_TYPES: Map<PAYMENT_TYPES, string> = new Map([
	[PAYMENT_TYPES.MONTHLY, 'Monthly Payments'],
	[PAYMENT_TYPES.DEFERRED, 'Deferred Until Due'],
]);

enum AFTER_REPAIR_VALUE_BASES {
	CERTIFIED_APPRAISAL = 'Certified Independent Appraisal',
	BPO = 'BPO',
	BORROWER_APPRAISAL = 'Borrower Appraisal',
	BORROWER_COMPS = 'Borrower Provided Comps',
	NONE = 'None'
}

const LABELED_AFTER_REPAIR_VALUE_BASES: Map<AFTER_REPAIR_VALUE_BASES, string> = new Map([
	[AFTER_REPAIR_VALUE_BASES.CERTIFIED_APPRAISAL, 'Certified Independent Appraisal'],
	[AFTER_REPAIR_VALUE_BASES.BPO, 'BPO'],
	[AFTER_REPAIR_VALUE_BASES.BORROWER_APPRAISAL, 'Borrower Appraisal'],
	[AFTER_REPAIR_VALUE_BASES.BORROWER_COMPS, 'Borrower Provided Comps'],
	[AFTER_REPAIR_VALUE_BASES.NONE, 'None'],
]);

enum SECURITY_POSITIONS {
	SENIOR = 'Senior',
	JUNIOR = 'Junior'
}

const LABELED_SECURITY_POSITIONS: Map<SECURITY_POSITIONS, string> = new Map([
	[SECURITY_POSITIONS.SENIOR, 'Senior'],
	[SECURITY_POSITIONS.JUNIOR, 'Junior'],
]);

enum STRATEGIC_FOCUSES {
	SINGLE_FAMILY = 'Single Family',
	FIX_AND_FLIP = 'Fix & Flip',
	NEW_CONSTRUCTION = 'New Construction',
	MULTI_FAMILY_TWO_TO_FOUR = 'Multifamily 2-4 Units',
	MULTI_FAMILY_FOUR = 'Multifamily 4+ Units',
	BUY_AND_HOLD = 'Buy & Hold',
	OTHER = 'Other',
	LAND = 'Land',
	THREE_TO_FIVE_YEAR_REFINANCE = '3-5 Year Refinance'
}

const LABELED_STRATEGIC_FOCUSES: Map<STRATEGIC_FOCUSES, string> = new Map([
	[STRATEGIC_FOCUSES.SINGLE_FAMILY, 'Single Family'],
	[STRATEGIC_FOCUSES.FIX_AND_FLIP, 'Fix & Flip'],
	[STRATEGIC_FOCUSES.NEW_CONSTRUCTION, 'New Construction'],
	[STRATEGIC_FOCUSES.MULTI_FAMILY_TWO_TO_FOUR, 'Multifamily 2-4 Units'],
	[STRATEGIC_FOCUSES.MULTI_FAMILY_FOUR, 'Multifamily 4+ Units'],
	[STRATEGIC_FOCUSES.OTHER, 'Other'],
	[STRATEGIC_FOCUSES.LAND, 'Land'],
	[STRATEGIC_FOCUSES.THREE_TO_FIVE_YEAR_REFINANCE, '3-5 Year Refinance'],
]);

const MIN_SCORE = 1;
const MAX_SCORE = 10;

const SANE_CENTS_VALIDATIONS = {
	required,
	integer,
	minValue: minValue(0),
	maxValue: maxValue(10000000000),
};

const SCORE_VALIDATIONS = {
	required,
	integer,
	minValue: minValue(MIN_SCORE),
	maxValue: maxValue(MAX_SCORE),
};

const COMMITMENT_SCORE_VALIDATIONS = {
	required,
	integer,
	minValue: minValue(0),
	maxValue: maxValue(1),
};

const INT_UNDER_10K_VALIDATIONS = {
	required,
	integer,
	minValue: minValue(0),
	maxValue: maxValue(9999),
};

const PAST_DATE_VALIDATIONS = {
	required,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	maxValue: (value: any) => value < new Date().toISOString(),
};

export default defineComponent({
	name: 'Publisher',
	setup() {
		const state = reactive({
			lendingData: null as any,
		});
		const rules = {
			lendingData: {
				loan: {
					name: {
						required,
					},
					amountCents: {
						...SANE_CENTS_VALIDATIONS,
					},
					portfolioAmountCents: {
						...SANE_CENTS_VALIDATIONS,
					},
					term: {
						required,
						decimal,
						minValue: minValue(1),
						maxValue: maxValue(2000),
					},
					loanMaturesOn: {
						required,
					},
					rate: {
						required,
						decimal,
						minValue: minValue(1),
						maxValue: maxValue(1000),
					},
					rating: {
						required,
					},
					repaymentPosition: {
						required,
						integer,
						minValue: minValue(1),
						maxValue: maxValue(200),
					},
					constructionSecondaryDrawAmountCents: {
						...SANE_CENTS_VALIDATIONS,
					},
					loanToValueScore: {
						...SCORE_VALIDATIONS,
					},
					qualityOfValuationReportScore: {
						...SCORE_VALIDATIONS,
					},
					locationScore: {
						...SCORE_VALIDATIONS,
					},
					borrowerExperienceScore: {
						...SCORE_VALIDATIONS,
					},
					borrowerCommitmentScore: {
						...COMMITMENT_SCORE_VALIDATIONS,
					},
				},
				address: {
					address1: {
						required,
					},
					address2: {
					},
					city: {
						required,
					},
					state: {
						required,
						minLength: minLength(2),
						maxLength: maxLength(2),
						alpha,
					},
					zipcode: {
						required,
						minLength: minLength(5),
						maxLength: maxLength(5),
						numeric,
					},
					longitude: {
						decimal,
					},
					latitude: {
						decimal,
					},
				},
				loanApplication: {
					purpose: {
						required,
					},
					paymentType: {
						required,
					},
					securityPosition: {
						required,
					},
					propertyType: {
						required,
					},
					totalRehabBudgetCents: {
						...SANE_CENTS_VALIDATIONS,
					},
					afterRepairValueCents: {
						...SANE_CENTS_VALIDATIONS,
					},
					afterRepairValueBasedOn: {
						required,
					},
					propertyValueCents: {
						...SANE_CENTS_VALIDATIONS,
					},
					borrowerCommitmentScore: {
						...SCORE_VALIDATIONS,
					},
					originalPurchaseDate: {
						...PAST_DATE_VALIDATIONS,
					},
				},
				principal: {
					firstName: {
						required,
					},
					lastName: {
						required,
					},
					principalStrategicFocus: {
						required,
					},
					fundedLoansCount: {
						...INT_UNDER_10K_VALIDATIONS,
					},
					repaidLoansCount: {
						...INT_UNDER_10K_VALIDATIONS,
					},
					repaymentPercentage: {
						required,
						decimal,
						minValue: minValue(0),
						maxValue: maxValue(100),
					},
					annuallyCompletedProjectsAverageCount: {
						...INT_UNDER_10K_VALIDATIONS,
					},
					averageProjectSalesPriceCents: {
						...SANE_CENTS_VALIDATIONS,
					},
					averageProjectsDurationMonths: {
						required,
						integer,
						minValue: minValue(0),
						maxValue: maxValue(1999),
					},
					averageProjectCostCents: {
						...SANE_CENTS_VALIDATIONS,
					},
				},
				borrowerEntity: {
					fullName: {
						required,
					},
					totalInventoryValueCents: {
						...SANE_CENTS_VALIDATIONS,
					},
					totalDebtCents: {
						...SANE_CENTS_VALIDATIONS,
					},
					unsoldUnits: {
						...INT_UNDER_10K_VALIDATIONS,
					},
					agedUnsoldUnit: {
						required,
					},
					projectsCompleted: {
						...INT_UNDER_10K_VALIDATIONS,
					},
					revenueCents: {
						...SANE_CENTS_VALIDATIONS,
					},
					grossMargin: {
						required,
						decimal,
						minValue: minValue(0),
						maxValue: maxValue(10000000000),
					},
					dateOfFormation: {
						...PAST_DATE_VALIDATIONS,
					},
				},
			},
		};
		const v$ = useVuelidate(rules, state);
		return { state, v$ };
	},
	data() {
		return {
			lendingLoanId: this.$route.params.loanId,
			lendingLoan: null as null | LendingLoan,
			dateOfFormation: null as null | Date,
			loanMaturesOn: null as null | Date,
			revenueCents: null as null | number,
			totalDebtCents: null as null | number,
			totalInventoryValueCents: null as null | number,
			VALID_LETTER_GRADES,
			LABELED_LOAN_PURPOSES,
			LABELED_PROPERTY_TYPES,
			LABELED_PAYMENT_TYPES,
			LABELED_AFTER_REPAIR_VALUE_BASES,
			LABELED_SECURITY_POSITIONS,
			MIN_SCORE,
			MAX_SCORE,
			US_STATES,
			LABELED_STRATEGIC_FOCUSES,
			selectedImage: null as null | GfImage,
			secBatches: [],
		};
	},
	computed: {
		selectableImages(): GfImage[] {
			if (this.lendingLoan) {
				const { propertyPhotos } = this.lendingLoan;
				const { coverImage } = this.lendingLoan.lendingLoanApplication;

				if (
					coverImage
					&& !propertyPhotos.map(p => p.id).includes(coverImage.id)
				) {
					return propertyPhotos.concat([coverImage]);
				} else {
					return propertyPhotos;
				}
			} else {
				return [];
			}
		},
	},
	watch: {
		dateOfFormation(newDate) {
			if (this.state.lendingData && this.state.lendingData.borrowerEntity) {
				this.state.lendingData.borrowerEntity.dateOfFormation = DateTime.fromJSDate(newDate).toISODate();
			}
		},
		loanMaturesOn(newDate) {
			if (this.state.lendingData) {
				this.state.lendingData.loan.loanMaturesOn = DateTime.fromJSDate(newDate).toISODate();
			}
		},
		revenueCents(newCents) {
			if (this.state.lendingData) {
				this.state.lendingData.borrowerEntity.revenueCents = String(newCents);
			}
		},

		totalInventoryValueCents(newCents) {
			if (this.state.lendingData) {
				this.state.lendingData.borrowerEntity.totalInventoryValueCents = String(newCents);
			}
		},

		totalDebtCents(newCents) {
			if (this.state.lendingData) {
				this.state.lendingData.borrowerEntity.totalDebtCents = String(newCents);
			}
		},
	},
	mounted() {
		this.fetchLoan();
		this.fetchSecBatches();
	},
	methods: {
		callGfMutation,
		async fetchSecBatches() {
			try {
				const { data } = await this.$apollo.query({
					query: SEC_BATCHES_QUERY,
					variables: {},
				});

				this.secBatches = data.secBatches;
			} catch (error) {
				console.error(error);

				this.$buefy.toast.open({
					message: 'Failed to fetch SEC batches.',
					type: 'is-danger',
				});
			}
		},
		fetchLoan() {
			fetchLendingLoan(this.$apollo.getClient(), this.$route.params.loanId)
				.then((lendingLoan) => {
					this.lendingLoan = lendingLoan;
					this.state.lendingData = getLendingDataConversionInputFromLendingLoan(lendingLoan);

					if (this.state.lendingData.borrowerEntity.dateOfFormation) {
						this.dateOfFormation = DateTime.fromISO(this.state.lendingData.borrowerEntity.dateOfFormation).toJSDate();
					}

					if (this.state.lendingData.loan.loanMaturesOn) {
						this.loanMaturesOn = DateTime.fromISO(this.state.lendingData.loan.loanMaturesOn).toJSDate();
					}

					if (this.state.lendingData.borrowerEntity.revenueCents) {
						this.revenueCents = Number(this.state.lendingData.borrowerEntity.revenueCents);
					}

					if (this.state.lendingData.borrowerEntity.totalDebtCents) {
						this.totalDebtCents = Number(this.state.lendingData.borrowerEntity.totalDebtCents);
					}

					if (this.state.lendingData.borrowerEntity.totalInventoryValueCents) {
						this.totalInventoryValueCents = Number(this.state.lendingData.borrowerEntity.totalInventoryValueCents);
					}

					// Always set security purchase configuration defaults on-render.
					this.state.lendingData.minimumUnitsPerAccount = 1;
					this.state.lendingData.unitPriceCents = 1000;
				})
				.catch((e) => {
					console.error('Error loading profile!', e);
					this.$buefy.toast.open({
						message: e.message,
						type: 'is-danger',
					});
				});
		},
		getAaUrl,
		handleSubmit() {
			if (this.state.lendingData) {
				const input = {
					lendingData: {
						...this.state.lendingData,
						imageId: this.selectedImage ? Number(this.selectedImage.id) : undefined,
					},
				};

				this.callGfMutation(
					this.$apollo,
					CREATE_INVESTMENT_OPPORTUNITY_FROM_LENDING_DATA_QUERY,
					'createInvestmentOpportunityFromLendingData',
					input,
				)
					.then(() => {
						this.$buefy.toast.open({
							message: 'Published!',
							type: 'is-success',
						});

						this.fetchLoan();
					}).catch((error) => {
						console.error(error);

						this.$buefy.toast.open({
							message: error.message,
							type: 'is-danger',
						});
					});
			}
		},
		getInputType(isValid: boolean) {
			return isValid ? '' : 'danger';
		},
		selectImage(image: GfImage) {
			this.selectedImage = this.selectedImage ? null : image;
		},
	},
});
