import Vue from 'vue';

import { namedRoutes } from '@/router/router';

export default Vue.extend({
	name: 'Private',
	data() {
		return {
			dashboardTo: {
				name: namedRoutes.Dashboard.name,
			},
			publisherTo: {
				name: namedRoutes.PublishableLoans.name,
			},
			progressUpdatesTo: {
				name: namedRoutes.ListProgressUpdates.name,
			},
			pendingTransfersTo: {
				name: namedRoutes.PendingTransfers.name,
			},
		};
	},
	computed: {
		canViewPublisher() {
			return this.$store.getters.canDoPublisher;
		},
	},
	methods: {
		handleLogout() {
			this.$store.dispatch('logout')
				.then(() => {
					this.$router.push({
						name: namedRoutes.Login.name,
					});
				})
				.catch((e) => {
					console.error(e);
					this.$buefy.toast.open({
						message: e.message,
						type: 'is-danger',
					});
				});
		},
	},
});
