import { render, staticRenderFns } from "./archive-progress-update.component.vue?vue&type=template&id=ac6b5160&"
import script from "./archive-progress-update.component.vue?vue&type=script&lang=ts&"
export * from "./archive-progress-update.component.vue?vue&type=script&lang=ts&"
import style0 from "./archive-progress-update.component.vue?vue&type=style&index=0&id=ac6b5160&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports