import { gql } from '@apollo/client/core';

export const PENDING_FUNDS_TRANSFERS_QUERY = gql`
	query PendingFundsTransfers {
		fundsTransfers(state: PENDING_REVIEW) {
			id
      amountCents
      direction

      account {
        type
      }

      user {
        name
      }
		}
	}
`;

export const APPROVE_FUNDS_TRANSFERS_MUTATION_NAME = 'approveFundsTransfers';

export const APPROVE_FUNDS_TRANSFERS_QUERY = gql`
	mutation ApproveFundsTransfers($input: ApproveFundsTransfersInput!) {
		${ APPROVE_FUNDS_TRANSFERS_MUTATION_NAME }(input: $input) {
			errors {
				status
				detail
				code
			}
		}
	}
`;

export const REJECT_FUNDS_TRANSFERS_MUTATION_NAME = 'rejectFundsTransfers';

export const REJECT_FUNDS_TRANSFERS_QUERY = gql`
	mutation RejectFundsTransfers($input: RejectFundsTransfersInput!) {
		${ REJECT_FUNDS_TRANSFERS_MUTATION_NAME }(input: $input) {
			errors {
				status
				detail
				code
			}
		}
	}
`;
